<template>
  <b-modal
    id="new-project-activity-modal"
    ref="new-project-activity-modal"
    centered
    header-class="d-flex justify-content-between align-items-center"
    :footer-class="
      isSavingActivity
        ? 'd-flex justify-content-end align-items-center'
        : 'd-flex justify-content-between align-items-center'
    "
    header-bg-variant="transparent"
    footer-bg-variant="light"
    no-close-on-backdrop
    no-close-on-esc
  >
    <template #modal-header>
      <h6 class="mb-0">{{ modalTitle }}</h6>
      <b-link @click="closeCreateActivityModal" :disabled="isSavingActivity">
        <i class="fa-solid fa-x"></i>
      </b-link>
    </template>

    <b-alert variant="danger" :show="errormessages.length > 0">
      <p
        v-for="(message, index) in errormessages"
        :key="index"
        :class="index === errormessages.length - 1 ? 'mb-0' : ''"
      >
        <span v-for="(erro, index) in message.erro" :key="index">{{ erro }}</span
        ><br />
      </p>
    </b-alert>

    <b-form @submit.prevent="handleSubmit" ref="formCreateActivity">
      <!-- <b-row>
        <b-col class="text-center">
          <span class="text-danger"
            >A atividade não poderá ser editada posteriormente.<br />Revise todas as informações.</span
          >
        </b-col>
      </b-row> -->
      <b-row>
        <b-col cols="12">
          <b-form-group label="Projeto" label-for="project_id">
            <v-select
              id="project_id"
              v-model.trim="$v.activity.project_id.$model"
              :options="projectsAvailable"
              :clearable="false"
              label="name"
              :reduce="(item) => item.id"
              :disabled="disabledProjectField"
            />
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.activity.project_id.$invalid">
              <p v-if="$v.activity.project_id.required == false">Campo obrigatório</p>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Atividade Base" label-for="standard_activity_id">
            <v-select
              id="standard_activity_id"
              v-model.trim="$v.activity.standard_activity_id.$model"
              :options="standardActivities"
              :clearable="false"
              label="title"
              :reduce="(type) => type.id"
            >
              <template #no-options>
                <em style="opacity: 0.5">Começe a digitar para buscar uma opção.</em>
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center mb-1">
                  <span class="font-weight-bold">{{ option.activity_code + " - " + option.title }}</span>
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  <span>{{ option.activity_code + " - " + option.title }}</span>
                </div>
              </template>
            </v-select>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.activity.standard_activity_id.$invalid">
              <p v-if="$v.activity.standard_activity_id.required == false">Campo obrigatório</p>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Título">
            <b-form-input size="sm" type="text" v-model.trim="$v.activity.title.$model"></b-form-input>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.activity.title.$invalid">
              <p v-if="$v.activity.title.required == false">Campo obrigatório</p>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Descrição">
            <b-form-input size="sm" type="text" v-model.trim="$v.activity.description.$model"></b-form-input>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.activity.description.$invalid">
              <p v-if="$v.activity.description.required == false">Campo obrigatório</p>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center" cols="6">
          <b-form-group label="Início Previsto">
            <date-picker
              id="start_date"
              name="start_date"
              v-model.trim="$v.activity.start_date.$model"
              lang="pt-br"
              type="date"
              value-type="DD-MM-YYYY"
              format="DD-MM-YYYY"
              input-class="cls-datepicker"
              :editable="true"
              :open.sync="openDatePickerInicio"
              autocomplete="off"
            >
              <template v-slot:input>
                <masked-input
                  type="text"
                  name="start_date_input"
                  class="cls-datepicker"
                  :value="$v.activity.start_date.$model"
                  :mask="dateMask"
                  :guide="false"
                  @change="handleChangeStartDate"
                  autocomplete="off"
                >
                </masked-input>
              </template>
              <template #footer="{ emit }">
                <button class="mx-btn mx-btn-text" @click="emit(today)">Hoje</button>
              </template>
            </date-picker>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.activity.start_date.$invalid">
              <p v-if="$v.activity.start_date.required == false">Campo obrigatório</p>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="text-center" cols="6">
          <b-form-group label="Término Previsto">
            <date-picker
              id="estimated_end_date"
              name="estimated_end_date"
              v-model.trim="$v.activity.estimated_end_date.$model"
              lang="pt-br"
              type="date"
              value-type="DD-MM-YYYY"
              format="DD-MM-YYYY"
              input-class="cls-datepicker"
              :editable="true"
              :open.sync="openDatePickerTermino"
              autocomplete="off"
            >
              <template v-slot:input>
                <masked-input
                  type="text"
                  name="estimated_end_date_input"
                  class="cls-datepicker"
                  :value="$v.activity.estimated_end_date.$model"
                  :mask="dateMask"
                  :guide="false"
                  @change="handleChangeEndDate"
                  autocomplete="off"
                >
                </masked-input>
              </template>
              <template #footer="{ emit }">
                <button class="mx-btn mx-btn-text" @click="emit(today)">Hoje</button>
              </template>
            </date-picker>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.activity.estimated_end_date.$invalid">
              <p v-if="$v.activity.estimated_end_date.required == false">Campo obrigatório</p>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col> </b-col>
      </b-row>
    </b-form>

    <template #modal-footer>
      <!-- <b-button
        v-show="!activity.id"
        size="sm"
        class="mr-1"
        :disabled="isSavingActivity"
        variant="secondary"
        @click="closeCreateActivityModal"
      >
        Cancelar
      </b-button> -->

      <b-button @click="submitForm('progress')" size="sm" v-if="!isSavingActivity" class="mr-1" variant="success">
        <i class="fa-regular fa-plus" /> Salvar e Cadastrar Progresso
      </b-button>
      <b-button @click="submitForm('continue')" size="sm" v-if="!isSavingActivity" class="mr-1" variant="primary">
        <i class="fa-regular fa-plus" /> Salvar e Criar Outra
      </b-button>
      <b-button @click="submitForm('close')" v-if="!isSavingActivity" size="sm" variant="primary">
        <i class="fa-regular fa-floppy-disk" /> Salvar
      </b-button>

      <b-button size="sm" v-if="isSavingActivity" variant="primary">
        <i class="fa-solid fa-spinner fa-spin"></i>
        Salvando
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Vue from "vue";
import ApiService from "../../common/api.service";

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
import { required } from "vuelidate/lib/validators";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
import MaskedInput from "vue-text-mask";
import moment from "moment";

export default {
  name: "CreateActivity",
  components: { DatePicker, MaskedInput },
  filters: {},
  props: {
    projectId: { type: Number, default: null },
  },
  data() {
    return {
      submitStatus: null,
      isSavingActivity: false,
      errormessages: [],
      now: new Date(),
      today: null,
      projectsAvailable: [],
      standardActivities: [],
      modalTitle: "Nova Atividade",
      submitMode: "close",
      disabledProjectField: false,

      // Variáveis para o date-picker
      openDatePickerInicio: false,
      openDatePickerTermino: false,
      dateMask: [/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
      // Fim Variáveis para o date-picker

      activity: {
        id: null,
        project_id: this.projectId,
        standard_activity_id: null,
        parent_id: null,
        title: null,
        activity_code: null,
        description: null,
        start_date: null,
        estimated_end_date: null,
        status: "not_started",
      },
    };
  },

  validations: {
    activity: {
      project_id: { required },
      standard_activity_id: { required },
      title: { required },
      activity_code: { required },
      description: { required },
      start_date: { required },
      estimated_end_date: { required },
    },
  },
  watch: {
    "activity.project_id": function (value) {
      if (value) {
        this.getCreateActivityStandardActivities(value);
      } else {
        this.standardActivities = [];
      }
    },
    "activity.standard_activity_id": function (value) {
      if (value) {
        const act = this.standardActivities.find((row) => {
          return row.id == value;
        });
        if (act) {
          this.activity.activity_code = act.activity_code;
        }
      }
    },
  },
  created: function () {},
  mounted: function () {
    this.today = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate());
  },
  methods: {
    openCreateActivityModal: function (activity) {
      this.getCreateActivityProjects();
      this.errormessages = [];
      if (this.projectId) {
        this.disabledProjectField = true;
        this.getCreateActivityStandardActivities(this.projectId);
      } else {
        this.disabledProjectField = false;
      }
      if (!activity) {
        this.modalTitle = "Nova Atividade";
        this.resetFields();
      } else {
        this.modalTitle = "Editar Atividade";

        this.activity.id = activity.id;
        this.activity.project_id = activity.project_id;
        this.activity.standard_activity_id = activity.standard_activity_id;
        this.activity.parent_id = activity.parent_id;
        this.activity.title = activity.title;
        this.activity.description = activity.description;
        this.activity.start_date = moment(activity.start_date).format("DD-MM-YYYY");
        this.activity.estimated_end_date = moment(activity.estimated_end_date).format("DD-MM-YYYY");
        this.activity.status = activity.status;
      }

      this.$bvModal.show("new-project-activity-modal");
    },
    closeCreateActivityModal: function () {
      this.$bvModal.hide("new-project-activity-modal");
    },
    getCreateActivityProjects: async function () {
      ApiService.get("activities/getCreateActivityProjects").then((response) => {
        if (response.data.status == "success") {
          this.projectsAvailable = response.data.projectsAvailable;
        } else {
          this.$showToast("Erro", "danger", "Ocorreu um erro durante a operação.");
        }
      });
    },
    getCreateActivityStandardActivities: async function (projectId) {
      ApiService.get(`activities/getCreateActivityStandardActivities/${projectId}`).then((response) => {
        if (response.data.status == "success") {
          this.standardActivities = response.data.standardActivities;
        } else {
          this.$showToast("Erro", "danger", response.data.message);
        }
      });
    },
    submitForm(mode) {
      this.submitMode = mode;
      this.$refs.formCreateActivity.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    },
    handleSubmit() {
      // e
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        // do your submit logic here
        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.postCreateProjectActivity();
          this.submitStatus = "OK";
        }, 500);
      }
    },
    postCreateProjectActivity: async function () {
      this.isSavingActivity = true;
      // this.resetFields();
      await ApiService.post("activities/postCreateProjectActivity", {
        // created_by_user_id,
        // responsable_user_id
        id: this.activity.id,
        project_id: this.activity.project_id,
        standard_activity_id: this.activity.standard_activity_id,
        parent_id: this.activity.parent_id,
        title: this.activity.title,
        activity_code: this.activity.activity_code,
        description: this.activity.description,
        start_date: this.activity.start_date,
        estimated_end_date: this.activity.estimated_end_date,
        status: this.activity.status,
      })
        .then((response) => {
          switch (response.status) {
            case 200:
              this.$showToast("Sucesso", "success", "Atividade cadastrada com sucesso.");
              this.resetFields();
              if (this.submitMode === "close") {
                this.closeCreateActivityModal();
              } else if (this.submitMode === "progress") {
                this.closeCreateActivityModal();
                // https://app.epalengenharia.test:8088/project/3/activities/11
                this.$store.commit("updateRegisterProgressOnEnterActivity", true);
                this.$router.push({
                  name: "project-activity",
                  params: { projectId: this.projectId, activityId: response.data.activity.id },
                });
                // this.$parent.$refs.createActivityProgressModal.openCreateActivityProgressModal(
                //   response.data.activity,
                //   null
                // );
              }
              this.$emit("created");
              break;

            case 422:
              this.errormessages = [];
              for (const [key, value] of Object.entries(response.data.errors)) {
                this.errormessages.push({ campo: key, erro: value });
              }
              this.$showToast("Erro", "danger", "Existem erros nas informações lançadas.");
              break;
            default:
              this.$showToast("Erro", "danger", "Ocorreu um erro durante a operação.");
              console.log(response);
              break;
          }
        })
        .finally(() => {
          this.isSavingActivity = false;
        });
    },
    resetFields() {
      // Object.assign(this.$data, this.$options.data.call(this));
      this.activity = {
        id: null,
        project_id: this.projectId ? this.projectId : null,
        standard_activity_id: null,
        parent_id: null,
        title: null,
        description: null,
        start_date: null,
        estimated_end_date: null,
        status: "not_started",
      };
    },
    handleChangeStartDate(evt) {
      this.activity.start_date = evt.target.value;
      this.openDatePickerInicio = false;
    },
    handleChangeEndDate(evt) {
      this.activity.estimated_end_date = evt.target.value;
      this.openDatePickerTermino = false;
    },
  },
};
</script>

<style scoped></style>
