<template>
  <b-modal
    id="create-activity-progress-modal"
    ref="create-activity-progress-modal"
    centered
    header-class="d-flex justify-content-between align-items-center"
    footer-class="d-flex justify-content-end align-items-center"
    header-bg-variant="transparent"
    footer-bg-variant="light"
    no-close-on-backdrop
    size="lg"
  >
    <template #modal-header>
      <h6 class="mb-0">{{ modalTitle }}</h6>
      <b-link @click="closeCreateActivityProgressModal">
        <i class="fa-solid fa-x"></i>
      </b-link>
    </template>
    <b-row>
      <b-col>
        Projeto: <span v-if="activity">{{ activity.project ? activity.project.name : null }}</span
        ><br />
        Atividade: <span v-if="activity">{{ activity.title }}</span>
      </b-col>
    </b-row>
    <hr class="my-2" />

    <b-alert variant="danger" :show="errormessages.length > 0">
      <p
        v-for="(message, index) in errormessages"
        :key="index"
        :class="index === errormessages.length - 1 ? 'mb-0' : ''"
      >
        <span v-for="(erro, index) in message.erro" :key="index">{{ erro }}</span
        ><br />
      </p>
    </b-alert>

    <b-form @submit.prevent="handleSubmit" ref="formCreateActivityProgress">
      <b-row>
        <b-col cols="6">
          <b-row>
            <b-col>
              <b-form-group label="Título">
                <b-form-input
                  size="sm"
                  type="text"
                  v-model.trim="$v.projectActivityProgress.title.$model"
                ></b-form-input>
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.projectActivityProgress.title.$invalid"
                >
                  <p v-if="$v.projectActivityProgress.title.required == false">Campo obrigatório</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <b-form-group label="Data e hora de início">
                <date-picker
                  id="start_date"
                  name="start_date"
                  v-model.trim="$v.projectActivityProgress.start_date.$model"
                  lang="pt-br"
                  type="datetime"
                  value-type="DD-MM-YYYY HH:mm"
                  format="DD-MM-YYYY HH:mm"
                  :disabled-date="notAfterToday"
                  :disabled-time="notAfterNow"
                  input-class="cls-datepicker"
                  :editable="true"
                  :open.sync="openDatePickerStartDate"
                  autocomplete="off"
                >
                  <template v-slot:input>
                    <masked-input
                      type="text"
                      name="start_date_input"
                      class="cls-datepicker"
                      :value="$v.projectActivityProgress.start_date.$model"
                      :mask="dateTimeMask"
                      :guide="false"
                      @change="handleChangeStartDate"
                      autocomplete="off"
                    >
                    </masked-input>
                  </template>
                  <template #footer="{ emit }">
                    <button class="mx-btn mx-btn-text" @click="emit(today)">Hoje</button>
                  </template>
                </date-picker>
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.projectActivityProgress.start_date.$invalid"
                >
                  <p v-if="$v.projectActivityProgress.start_date.required == false">Campo obrigatório</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Data e hora de término">
                <date-picker
                  id="end_date"
                  name="end_date"
                  :key="elementKey"
                  v-model.trim="$v.projectActivityProgress.end_date.$model"
                  lang="pt-br"
                  type="datetime"
                  value-type="DD-MM-YYYY HH:mm"
                  format="DD-MM-YYYY HH:mm"
                  :disabled-date="notBeforeBeginAndNotAfterToday"
                  :disabled-time="notBeforeBeginAndNotAfterNow"
                  input-class="cls-datepicker"
                  :editable="true"
                  :open.sync="openDatePickerEndDate"
                  autocomplete="off"
                >
                  <template v-slot:input>
                    <masked-input
                      type="text"
                      name="end_date_input"
                      class="cls-datepicker"
                      :value="$v.projectActivityProgress.end_date.$model"
                      :mask="dateTimeMask"
                      :guide="false"
                      @change="handleChangeEndDate"
                      autocomplete="off"
                    >
                    </masked-input>
                  </template>
                  <template #footer="{ emit }">
                    <button class="mx-btn mx-btn-text" @click="emit(today)">Hoje</button>
                  </template>
                </date-picker>
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.projectActivityProgress.end_date.$invalid"
                >
                  <p v-if="$v.projectActivityProgress.end_date.required == false">Campo obrigatório</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col class="text-center">
              <span class="text-danger"
                >O horário não poderá ser editado posteriormente. Revise todas as informações.</span
              >
            </b-col>
          </b-row> -->
          <b-row>
            <b-col>
              <b-form-group label="Status">
                <v-select
                  :clearable="false"
                  v-model="$v.projectActivityProgress.status.$model"
                  :options="statusPossible"
                  :reduce="(item) => item.code"
                />
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.projectActivityProgress.status.$invalid"
                >
                  <p v-if="$v.projectActivityProgress.status.required == false">Campo obrigatório</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <template #label>
                  <span>Porcentagem de conclusão:</span> {{ projectActivityProgress.completion_percentage }}%
                </template>
                <b-form-input
                  id="completionPercent"
                  v-model="$v.projectActivityProgress.completion_percentage.$model"
                  type="range"
                  min="0"
                  max="100"
                  step="10"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group label="Usuário">
                <v-select
                  v-model.trim="$v.projectActivityProgress.user_id.$model"
                  :options="users"
                  :clearable="false"
                  label="name"
                  :reduce="(opt) => opt.id"
                  disabled
                >
                  <template slot="no-options"> Digite para buscar itens </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.name }} <br />
                      <span class="font-italic">{{
                        option.roles && option.roles.length != 0 ? option.roles[0].display_name : "Sem Papel definido"
                      }}</span>
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.name }} <br />
                      <span class="font-italic">{{
                        option.roles && option.roles.length != 0 ? option.roles[0].display_name : "Sem Papel definido"
                      }}</span>
                    </div>
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.projectActivityProgress.user_id.$invalid"
                >
                  <p v-if="$v.projectActivityProgress.user_id.required == false">Campo obrigatório</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Item do Contrato">
                <v-select
                  v-model.trim="$v.projectActivityProgress.contract_item_id.$model"
                  :options="contractItems"
                  :clearable="false"
                  label="name"
                  :reduce="(opt) => opt.id"
                >
                  <template slot="no-options"> Digite para buscar itens </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.name }} <br />
                      <span class="font-italic">{{ option.contract ? option.contract.name : null }}</span>
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.name }} <br />
                      <span class="font-italic">{{ option.contract ? option.contract.name : null }}</span>
                    </div>
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.projectActivityProgress.contract_item_id.$invalid"
                >
                  <p v-if="$v.projectActivityProgress.contract_item_id.required == false">Campo obrigatório</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="6">
          <b-form-group id="textareaTitle" class="mb-1" label="Relatório do progresso:">
            <b-form-textarea v-model="$v.projectActivityProgress.body.$model" :style="textareaStyle"></b-form-textarea>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.projectActivityProgress.body.$invalid">
              <p v-if="$v.projectActivityProgress.body.required == false">Campo obrigatório</p>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex justify-content-center align-items-center">
          <span class="mr-1">Lat.:{{ location.latitude }} </span>
          <span class="mr-1">Lon.:{{ location.longitude }} </span>
        </b-col>
      </b-row>
    </b-form>
    <template #modal-footer>
      <b-button
        v-if="!isSavingActivity"
        variant="primary"
        size="sm"
        @click="submitForm()"
        :disabled="!location.latitude"
      >
        <i class="fa-light fa-floppy-disk mr-2"></i>
        Salvar
      </b-button>
      <b-button v-if="isSavingActivity" size="sm" variant="primary">
        <i class="fa-solid fa-spinner fa-spin"></i>
        Salvando
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import Vue from "vue";
import ApiService from "../../common/api.service";

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
import { required, requiredIf } from "vuelidate/lib/validators";
// import { helpers } from "vuelidate/lib/validators";
// const alphaNum = helpers.regex("alphaNum", /^[a-zA-Z0-9À-ü'\s.]*$/);

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
import MaskedInput from "vue-text-mask";

import moment from "moment";

export default {
  name: "ActivityProgress",
  components: { DatePicker, MaskedInput },
  props: {},
  data() {
    return {
      isLoading: false,
      isLoadingActivity: false,
      isSavingActivity: false,
      today: new Date(),
      errormessages: [],

      submitStatus: null,

      activity: null,
      statusProgress: { label: "Em Progresso", code: "in_progress" },
      statusPossible: [
        { label: "Não Iniciado", code: "not_started" },
        { label: "Em Progresso", code: "in_progress" },
        { label: "Completo", code: "completed" },
      ],
      users: [],
      contracts: [],
      contractItems: [],
      location: this.$store.state.location,

      projectActivityProgress: {
        id: null,
        project_id: null,
        project_activity_id: null,
        contract_id: null,
        contract_item_id: null,
        user_id: this.$store.state.auth.user.id,
        user_role_id: null,
        report_date: moment().format("DD-MM-YYYY HH:mm"),
        start_date: moment().subtract(2, "hour").format("DD-MM-YYYY HH:mm"),
        end_date: moment().format("DD-MM-YYYY HH:mm"),
        duration: null,
        title: null,
        progress_description: null,
        body: "",
        status: "not_started",
        completion_percentage: 0,
      },

      // Variáveis para o date-picker
      openDatePickerReportDate: false,
      openDatePickerStartDate: false,
      openDatePickerEndDate: false,
      dateMask: [/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
      dateTimeMask: [/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, ":", /\d/, /\d/],
      // Fim Variáveis para o date-picker

      textareaStyle: null,

      elementKey: 0,
    };
  },

  validations() {
    const checkProjectPermission = this.$checkProjectPermission;
    return {
      projectActivityProgress: {
        contract_id: {
          required: requiredIf(() => {
            return !checkProjectPermission("pode-criar-progresso-sem-contrato");
          }),
        },
        contract_item_id: {
          required: requiredIf(() => {
            return !checkProjectPermission("pode-criar-progresso-sem-contrato");
          }),
        },
        user_id: { required },
        user_role_id: {},
        report_date: { required },
        start_date: { required },
        end_date: { required },
        title: { required },
        progress_description: {},
        body: { required },
        status: { required },
        completion_percentage: { required },
      },
    };
  },
  watch: {
    "projectActivityProgress.completion_percentage": function (value) {
      if (value) {
        switch (Number(value)) {
          case 0:
            this.projectActivityProgress.status = "not_started";
            break;
          case 100:
            this.projectActivityProgress.status = "completed";
            break;
          default:
            this.projectActivityProgress.status = "in_progress";
            break;
        }
      }
    },
    "projectActivityProgress.status": function (value) {
      if (value) {
        switch (value) {
          case "not_started":
            this.projectActivityProgress.completion_percentage = 0;
            break;
          case "completed":
            this.projectActivityProgress.completion_percentage = 100;
            break;
          default:
            if (!this.projectActivityProgress.id) {
              if (this.projectActivityProgress.completion_percentage == 100) {
                this.projectActivityProgress.completion_percentage = 90;
              } else if (this.projectActivityProgress.completion_percentage == 0) {
                this.projectActivityProgress.completion_percentage = 10;
              }
            }
            break;
        }
      }
    },
    "projectActivityProgress.contract_item_id": function (value) {
      if (value) {
        const index = this.contractItems.findIndex((itm) => itm.id === value);
        if (index > -1) {
          this.projectActivityProgress.contract_id = this.contractItems[index].contract.id;
        }
      }
    },
    "projectActivityProgress.start_date": function () {
      this.elementKey += 1;
    },
  },

  created: function () {},
  computed: {
    //
  },
  mounted() {},
  methods: {
    openCreateActivityProgressModal: function (activity, activityProgress) {
      if (activity) {
        this.activity = activity;
      }

      if (!activityProgress) {
        this.modalTitle = "Novo Progresso";
        this.projectActivityProgress = {
          id: null,
          project_id: this.activity.project_id,
          project_activity_id: this.activity.id,
          contract_id: null,
          contract_item_id: null,
          user_id: this.$store.state.auth.user.id,
          user_role_id: null,
          report_date: moment().format("DD-MM-YYYY HH:mm"),
          start_date: moment().subtract(2, "hour").format("DD-MM-YYYY HH:mm"),
          end_date: moment().format("DD-MM-YYYY HH:mm"),
          duration: null,
          title: null,
          progress_description: null,
          body: "",
          status: "not_started",
          completion_percentage: 0,
        };
      } else {
        this.modalTitle = "Editar Progresso";

        this.projectActivityProgress.id = activityProgress.id;
        this.projectActivityProgress.project_id = activityProgress.project_id;
        this.projectActivityProgress.contract_id = activityProgress.contract_id;
        this.projectActivityProgress.contract_item_id = activityProgress.contract_item_id;
        this.projectActivityProgress.project_activity_id = activityProgress.project_activity_id;
        this.projectActivityProgress.user_id = activityProgress.user_id;
        this.projectActivityProgress.user_role_id = activityProgress.user_role_id;
        this.projectActivityProgress.report_date = moment(activityProgress.report_date).format("DD-MM-YYYY HH:mm");
        this.projectActivityProgress.start_date = moment(activityProgress.start_date).format("DD-MM-YYYY HH:mm");
        this.projectActivityProgress.end_date = moment(activityProgress.end_date).format("DD-MM-YYYY HH:mm");
        this.projectActivityProgress.duration = activityProgress.duration;
        this.projectActivityProgress.title = activityProgress.title;
        this.projectActivityProgress.progress_description = activityProgress.progress_description;
        this.projectActivityProgress.body = activityProgress.body;
        this.projectActivityProgress.status = activityProgress.status;
        this.projectActivityProgress.completion_percentage = activityProgress.completion_percentage;
      }
      this.errormessages = [];
      this.$bvModal.show("create-activity-progress-modal");

      Vue.nextTick(() => {
        this.getHeight();
        this.getCreateActivityProgressInitialData();
      });
    },
    closeCreateActivityProgressModal: function () {
      this.$bvModal.hide("create-activity-progress-modal");
    },
    getCreateActivityProgressInitialData: async function () {
      ApiService.get(`activities/getCreateActivityProgressInitialData/${this.activity.project_id}`).then((response) => {
        if (response.status == 200) {
          this.users = response.data.users;
          this.contracts = response.data.contracts;
          this.contractItems = response.data.contractItems;
        } else {
          this.$showToast("Erro", "danger", "Ocorreu um erro durante a operação.");
        }
      });
    },
    notAfterToday(date) {
      // return date > new Date(new Date().setHours(0, 0, 0, 0));
      return date > new Date(new Date());
    },
    notAfterNow(date) {
      return date > new Date(new Date());
    },
    notBeforeBeginAndNotAfterToday(date) {
      // const startDate = moment(this.projectActivityProgress.start_date, "DD-MM-YYYY HH:mm");
      // console.log(startDate);
      // date <= new Date(startDate) ||
      return date > new Date(this.today);
    },
    notBeforeBeginAndNotAfterNow(date) {
      // const startDate = moment(this.projectActivityProgress.start_date, "DD-MM-YYYY HH:mm");
      // date <= new Date(startDate) ||
      return date > new Date(this.today);
    },
    submitForm() {
      this.$refs.formCreateActivityProgress.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    },
    handleSubmit() {
      // e
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        // do your submit logic here
        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.createActivityProgress();
          this.submitStatus = "OK";
        }, 500);
      }
    },
    createActivityProgress: async function () {
      this.isSavingActivity = true;

      // Obtenha as coordenadas do Vuex Store
      const location = this.$store.state.location;

      await ApiService.post("activities/createActivityProgress", {
        id: this.projectActivityProgress.id,
        project_id: this.projectActivityProgress.project_id,
        project_activity_id: this.projectActivityProgress.project_activity_id,
        contract_id: this.projectActivityProgress.contract_id,
        contract_item_id: this.projectActivityProgress.contract_item_id,
        user_id: this.projectActivityProgress.user_id,
        user_role_id: this.projectActivityProgress.user_role_id,
        report_date: this.projectActivityProgress.report_date,
        start_date: this.projectActivityProgress.start_date,
        end_date: this.projectActivityProgress.end_date,
        duration: this.projectActivityProgress.duration,
        title: this.projectActivityProgress.title,
        progress_description: this.projectActivityProgress.progress_description,
        body: this.projectActivityProgress.body,
        status: this.projectActivityProgress.status,
        completion_percentage: this.projectActivityProgress.completion_percentage,

        // Incluindo as coordenadas obtidas do Vuex
        latitude: location.latitude,
        longitude: location.longitude,
        accuracy: location.accuracy,
      })
        .then((response) => {
          switch (response.status) {
            case 200:
              this.resetFields();
              this.closeCreateActivityProgressModal();
              this.$emit("created");
              this.$showToast("Sucesso", "success", "Dados gravados com sucesso.");
              break;

            case 422:
              this.errormessages = [];
              for (const [key, value] of Object.entries(response.data.errors)) {
                this.errormessages.push({ campo: key, erro: value });
              }
              this.$showToast("Erro", "danger", "Existem erros nas informações lançadas.");
              break;
            default:
              console.log(response);
              break;
          }
        })
        .finally(() => {
          this.isSavingActivity = false;
        });
    },
    resetFields() {
      // Object.assign(this.$data, this.$options.data.call(this));
      this.projectActivityProgress = {
        id: null,
        project_id: this.activity.project_id,
        project_activity_id: this.activity.id,
        contract_id: null,
        contract_item_id: null,
        user_id: null,
        user_role_id: null,
        report_date: null,
        start_date: null,
        end_date: null,
        duration: null,
        title: null,
        progress_description: null,
        body: "",
        status: "not_started",
        completion_percentage: 0,
      };
    },
    handleChangeReportDate(evt) {
      this.projectActivityProgress.report_date = evt.target.value;
      this.openDatePickerReportDate = false;
    },
    handleChangeStartDate(evt) {
      this.projectActivityProgress.start_date = evt.target.value;
      this.openDatePickerStartDate = false;
    },
    handleChangeEndDate(evt) {
      this.projectActivityProgress.end_date = evt.target.value;
      this.openDatePickerEndDate = false;
    },
    getHeight: function () {
      const elemento = document.getElementById("textareaTitle");
      if (elemento) {
        const altura = "50px";
        this.textareaStyle = `height: calc(100% - ${altura})`;
      }
    },
  },
};
</script>
<style scoped></style>
